import React, { useEffect } from "react";
import { AppContext } from "../../../Context/Context";
import Button from "../../../Components/Button/Button";
import { authentication } from "../../../Api/authentication";
import Logo from "../../../Assets/Images/logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.css";
import { CARL_LINK } from "../../../Constant/onboardingData";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";

const NumberVarification = ({
  handleToUpdate,
  setIsEmail,
  setExistingEmail,
}: {
  handleToUpdate: (val: boolean) => void;
  setIsEmail: (email: boolean) => void;
  setExistingEmail: (email: string) => void;
}) => {
  const {
    phone_Number,
    set_phone_number,
    setUserId,
    isUSResident,
    loginEmail,
    setIsExistingUser,
    setusResident,
  } = React.useContext(AppContext);

  const [error, setError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [isInvalidEmail, setisInvalidEmail] = React.useState(false);
  const [errorSWW, setErrorSWW] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [checkCountryCode, setCheckCountryCode] = React.useState("");
  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] =
    React.useState("");

  const validatePhoneNumber = (
    phoneWithoutCountryCode: string,
    countryCode: string
  ) => {
    const firstDigit = phoneWithoutCountryCode[0];

    // Check if the first digit is neither 0 nor 1
    if (firstDigit === "0" || firstDigit === "1") {
      return false;
    }

    if (
      (["us", "ca", "mx", "jp", "ng"].includes(countryCode) &&
        phoneWithoutCountryCode.length === 10) ||
      (countryCode === "in" && /^[6-9]\d{9}$/.test(phoneWithoutCountryCode))
    ) {
      return true;
    }
    return false;
  };

  const handlePhoneChange = (value: string, countryData: any) => {
    set_phone_number(`+${value}`);
    sessionStorage.setItem("phoneNumber", `+${value}`);
    const countryCode = countryData.countryCode;
    const dialCode = countryData.dialCode;
    const phoneWithoutCountryCode = value
      .replace(/[^0-9]/g, "")
      .slice(dialCode.length);
    setPhoneWithoutCountryCode(phoneWithoutCountryCode);

    // Check if phone number is valid
    if (
      validatePhoneNumber(phoneWithoutCountryCode, countryCode) &&
      isValidPhoneNumber(`+${value}`)
    ) {
      setCheckCountryCode(countryCode);
      setIsButtonDisabled(false);
      setError(false);
    } else if (
      isValidPhoneNumber(`+${value}`) &&
      value.length >= 8 &&
      value.length <= 15 &&
      !["us", "ca", "in", "mx", "jp", "ng"].includes(countryCode)
    ) {
      setError(false);
      setIsButtonDisabled(false);
    } else {
      setError(true);
      setIsButtonDisabled(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = event.currentTarget; // Get the current input element
    const cursorPosition = inputElement.selectionStart; // Get the current cursor position

    // Prevent deleting the '+' sign
    if (cursorPosition === 1 && event.key === "Backspace") {
      event.preventDefault();
    }

    // If the input only has the '+' sign and backspace is pressed, do not allow removal
    if (phone_Number === "+" && event.key === "Backspace") {
      event.preventDefault();
    }

    // Prevent editing if the phone number is exactly 10 digits and backspace is pressed when cursor is not next to '+'
    if (
      (phoneWithoutCountryCode.length === 10 &&
        event.key !== "Tab" &&
        event.key !== "Backspace" &&
        event.key !== "Delete" &&
        event.key !== "ArrowUp" &&
        event.key !== "ArrowDown" &&
        event.key !== "ArrowLeft" &&
        event.key !== "ArrowRight") ||
      (event.key === "Backspace" && cursorPosition === 1) // Check if cursor is right next to '+'
    ) {
      event.preventDefault();
    }

    // If cursor is at position 0, set it to position 1
    if (cursorPosition === 0) {
      // Move the cursor to position 1
      setTimeout(() => {
        inputElement.setSelectionRange(1, 1); // Set cursor to position 1
      }, 0);
    }
  };

  useEffect(() => {
    set_phone_number("+1");
    sessionStorage.clear();
    sessionStorage.clear();
  }, []);

  const loginHandler = async () => {
    try {
      const response = await authentication.signIn(phone_Number, loginEmail);
      if (response.status) {
        setIsEmail(response.message.to_email);
        setExistingEmail(response.message.email);
        set_phone_number(response.message.phone_number);
        setUserId(response.message.id);
        sessionStorage.setItem("userId", JSON.stringify(response.message.id));
        setLoading(false);
      }
      setErrorSWW(true);
    } catch {
      setLoading(true);
    }
  };

  const Submit = async () => {
    setEmailError(false);
    setisInvalidEmail(false);
    setError(false);
    setErrorSWW(false);
    setLoading(true);

    if (checkCountryCode === "us") {
      setusResident(true);
      sessionStorage.setItem("usResident", JSON.stringify(true));
    } else {
      setusResident(false);
      sessionStorage.setItem("usResident", JSON.stringify(false));
    }

    const phone = parsePhoneNumberFromString(phone_Number);
    if (phone && !isValidPhoneNumber(phone_Number)) {
      setError(true);
      setLoading(false);
      return;
    }

    const res = await checkExistingUser(phone_Number);

    if (res.message.existing_user) {
      handleToUpdate(res?.message?.existing_user);
      setIsExistingUser(res?.message?.existing_user);
      return loginHandler();
    } else {
      handleToUpdate(res?.message?.existing_user);
      setLoading(false);
    }
  };

  const checkExistingUser = async (phone_number: string) => {
    const response = await authentication.existingUser(phone_number);
    return response;
  };

  return (
    <div className="container">
      <div className="header">
        <div>
          <div className="verificationHeader">
            <img src={Logo} alt="logo" />
            <h5 className="mainTitle">Welcome To CARL</h5>
          </div>
          <div>
            <h6 className="title">Enter your mobile number to continue</h6>
          </div>
          <div>
            <h6 className="titleNumber">Mobile number</h6>
          </div>
          <PhoneInput
            country={"us"}
            value={phone_Number}
            onChange={handlePhoneChange}
            onKeyDown={handleKeyDown} // Handle key down event
            inputStyle={{
              height: "100%",
              width: "100%",
              fontSize: window.innerWidth <= 600 ? "16px" : "24px",
              border: "none",
              borderRadius: "5px",
              outline: "none",
              padding: "10px",
              paddingLeft: "50px",
              backgroundColor: "rgb(37, 53, 111)",
              color: "white",
            }}
            inputClass="Input"
          />
        </div>
        {error ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Please enter a valid mobile number.
          </h6>
        ) : emailError ? (
          <h6
            style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}
          />
        ) : isInvalidEmail ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Please enter a valid email address.
          </h6>
        ) : errorSWW ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Email already exists.
          </h6>
        ) : null}
        <div className="continueBtn">
          <Button
            label="Continue"
            className={!isButtonDisabled ? "buttonContinue" : "deactiveButton"}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            disabled={loading || isButtonDisabled}
            onClick={Submit}
          />
        </div>
        <div>
          <p className="footer">
            Trouble logging in? Email us at
            <span className="footerSpan">
              <a
                className="termLink"
                href={`mailto:${CARL_LINK}`}
                target="_blank"
                rel="noreferrer"
              >
                {CARL_LINK}
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NumberVarification;
