import * as FaIcons from "react-icons/fa";
const ProfilePop = (props) => {
  return (
    <div className="px-4 p-2">
      <h3 className="text-white">
        <strong>Profile</strong>
      </h3>
      <div className="profile_top"></div>
      <div className="profile_body">
        <div className="d-flex align-items-center">
          <div className="position-relative">
            <div className="profile_img">
              <div className="my_img">
                <img
                  src={
                    props?.userContent?.message?.data?.kycinfodata
                      ?.frontImageUrl
                  }
                  className="profile_image"
                  alt="small Logo"
                />
              </div>
              <div className="catch_profile">
                <FaIcons.FaCamera />
              </div>
            </div>
          </div>
          <div className="fs-4 text-white">
            <strong>
              {props?.userContent?.message?.data?.personalinfodata?.first_name}{" "}
              {props?.userContent?.message?.data?.personalinfodata?.last_name}
            </strong>
          </div>
        </div>
        <div className="p-5 d-flex flex-wrap">
          <div className="w-33">
            <div className="text-white-50">First Name</div>
            <div className="text-white">
              {props?.userContent?.message?.data?.personalinfodata?.first_name}
            </div>
          </div>
          <div className="w-33">
            <div className="text-white-50">Last Name</div>
            <div className="text-white">
              {props?.userContent?.message?.data?.personalinfodata?.last_name}
            </div>
          </div>
          <div className="w-33">
            <div className="text-white-50">Investor Type</div>
            <div className="text-white">
              {
                props?.userContent?.message?.data?.personalinfodata
                  ?.investor_type
              }
            </div>
          </div>
          <div className="w-33">
            <div className="text-white-50">Phone Number</div>
            <div className="text-white">
              {
                props?.userContent?.message?.data?.personalinfodata
                  ?.phone_number
              }
            </div>
          </div>
          <div className="w-33">
            <div className="text-white-50">Email ID</div>
            <div className="text-white">
              {props?.userContent?.message?.data?.personalinfodata?.email}
            </div>
          </div>
          <div className="w-33">
            <div className="text-white-50">Address</div>
            <div className="text-white">
              {props?.userContent?.message?.data?.addressinfodata?.address1},{" "}
              {props?.userContent?.message?.data?.addressinfodata?.address2},{" "}
              {props?.userContent?.message?.data?.addressinfodata?.city}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePop;
