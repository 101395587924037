import { useState } from "react";
import PrivcyIcon from "../../Assets/Images/shield.png";
import "./PrivacyPolicy.css";
import ArrowForward from "../../Assets/svg/arrowforward";
import PrivacyModal from "./PrivacyModal";

const PrivacyContainer = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="privacyContainer" onClick={() => setShow(true)}>
        <img src={PrivcyIcon} width={20} alt="Back-Button" />
        <p className="conatinerText">
          How we protect your information and why we ask for it?
        </p>
        <ArrowForward />
      </div>
      {show ? <PrivacyModal setShow={setShow} show={show} /> : null}
    </>
  );
};

export default PrivacyContainer;
