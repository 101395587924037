import React, { useEffect } from "react";
import MainStepper from "../../../Components/Stepper/MainStepper";
import VerifiAddressScreen from "../../Onboarding/AddressScreens/VerifiAddressScreen";
import AddressInformation from "../../Onboarding/AddressScreens/AddressDetails";
import SuccessScreen from "../../../Components/SuccessScreen/SuccessScreen";
import { Routes } from "../../../Constant/Routes/Routes";
import UploadDocuments from "../../Onboarding/IdentificationScreens/UploadDocuments";
import Preview from "../../Onboarding/AddressScreens/Preview";
import { Info } from "../../../Api/onBoarding";

type props = {
  title?: string;
  id?: number;
};

const arraySteps = [
  { title: "Verify Address" },
  { title: "Address Details" },
  { title: "Upload Documents" },
  { title: "Preview" },
  { title: "Success" },
];

const AddressStepper = () => {
  const [currentStep, setcurrentStep] = React.useState(0);
  const [title, settitle] = React.useState<props>({
    title: "Address Proof",
    id: 1,
  });

  useEffect(() => {
    Info.GetUserDetails()
      .then((res) => {
        if (res.message.data.addressinfodata.address1 === undefined) {
          setcurrentStep(0);
        } else if (
          res.message.data.addressinfodata.address1 !== undefined &&
          res.message.data.addressinfodata.address1 !== "" &&
          res.message.data.addressinfodata.docFile === null
        ) {
          setcurrentStep(2);
        } else if (
          res.message.data.addressinfodata.address1 !== undefined &&
          res.message.data.addressinfodata.address1 !== "" &&
          res.message.data.addressinfodata.docFile !== null
        ) {
          setcurrentStep(3);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleToUpdate = (val: any) => {
    if (val == "back") {
      setcurrentStep(currentStep - 1);
    } else {
      setcurrentStep(currentStep + 1);
    }
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return (
          <VerifiAddressScreen
            handleToUpdate={() => setcurrentStep(currentStep + 1)}
            setcurrentStep={setcurrentStep}
          />
        );
      case 1:
        return (
          <AddressInformation
            handleToUpdate={() => setcurrentStep(currentStep + 1)}
            setcurrentStep={setcurrentStep}
          />
        );
      case 2:
        return (
          <UploadDocuments
            handleToUpdate={() => setcurrentStep(currentStep + 1)}
            documentTitle={title}
            setcurrentStep={setcurrentStep}
            isAddr={true}
          />
        );
      case 3:
        return <Preview handleToUpdate={handleToUpdate} />;
      case 4:
        return (
          <SuccessScreen
            title="Success"
            heading="We have received your address details"
            navigate={Routes.IDENTIFICATION}
          />
        );
      default:
        return null;
    }
  };

  return (
    <MainStepper currentStep={currentStep} stepsArray={arraySteps}>
      {renderComponent()}
    </MainStepper>
  );
};

export default AddressStepper;
