import * as React from "react";
import { useHistory } from "react-router";
import SuccesLogo from "../../Assets/Images/check.png";
import "../../Components/SuccessScreen/SuccessScreen";
import { GetUserStatus } from "../../Api/onBoarding";
import { AppContext } from "../../Context/Context";
interface SuccessScreenProps {
  title: string;
  heading: string;
  navigate?: string;
  button?: string;
  label?: string;
}

const HomeScreen = ({
  title,
  heading,
  navigate,
  button,
  label,
}: SuccessScreenProps) => {
  const history = useHistory();

  const { setApplicationStatus, userId } = React.useContext(AppContext);

  const checkFlow = React.useCallback(async () => {
    const data = await GetUserStatus.fetchMainData(userId);
    setApplicationStatus(data.message.application_status);
  }, [setApplicationStatus, userId]);

  React.useEffect(() => {
    checkFlow();
    setTimeout(() => {
      sessionStorage.clear();
      history.replace("/");
    }, 5000);
  }, [checkFlow]);

  const handler = () => {
    history.replace(`/${navigate}`);
  };

  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="logoContainer">
            <img src={SuccesLogo} alt="logo" />
          </div>
          <div className="successScreenContainer">
            <h2
              className="succesTitle"
              style={{ color: "white", fontSize: "32px" }}
            >
              Your application is complete
            </h2>
            <h5 className="title" style={{ fontSize: "20px" }}>
              It usually takes no more than 48hrs to approve. We will notify you
              when your account is ready.
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
