import React from "react";
import Webcam from "react-webcam";
import Capture from "../../Assets/Images/camera.png";
import "./style.css";
interface CameraProps {
  setFrontImage?: any;
  setBackImage?: any;
  setFrontImageURL?: any;
  setBackImageURL?: any;
}

const Camera: React.FC<CameraProps> = ({
  setFrontImage,
  setBackImage,
  setFrontImageURL,
  setBackImageURL,
}) => {
  const webcamRef = React.useRef<any>(null);

  const videoConstraints = {
    facingMode: { exact: "environment" },
  };
  const capture = () => {
    const url = webcamRef.current.getScreenshot();

    if (setFrontImage) {
      setFrontImageURL(url);
    } else {
      setBackImageURL(url);
    }

    fetch(url)
      .then((res) => res.blob())
      .then((response) => {
        if (setFrontImage) {
          setFrontImage(response);
        } else {
          setBackImage(response);
        }
      });
  };
  return (
    <>
      <Webcam
        audio={false}
        height={"100%"}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={"100%"}
        videoConstraints={videoConstraints}
      />
      <button onClick={capture} className="captureButton">
        <img src={Capture} alt="Capture" className="captureLogo" />
      </button>
    </>
  );
};

export default Camera;
