import * as React from "react";
import { isMobile } from "react-device-detect";

import FileUpload from "../Input/FileUploadInput/FileUploadInput";
import Camera from "./OpenCamera";
import ShowCapturedImages from "./ShowCapturedImage";
import succes from "../../Assets/Images/right_icon-removebg-preview.png"
interface MobileComponentProps {
    frontImage: File | null;
    backImage: File | null;
    flipSide: string;
    setFrontImage: (file: File | null) => void;
    setBackImage: (file: File | null) => void;
    retakeImage: () => void;
    disabled: boolean;
  }
  
  const MobileComponent: React.FC<MobileComponentProps> = ({
    frontImage,
    backImage,
    flipSide,
    setFrontImage,
    setBackImage,
    retakeImage,
    disabled
  }) => {
    const [frontImageURL, setFrontImageURL] = React.useState("");
    const [backImageURL, setBackImageURL] = React.useState("");
  
    const isFrontImageTaken = flipSide === "front" && !frontImage;


    const retakeImageHandler = () =>{
      setFrontImageURL("");
      setBackImageURL("");
      retakeImage();
    }
  
    if (isMobile)
      return !frontImage || !backImage ? (
        <>
          <div className="titleMain">
            <div>
              <h4 className="heading">
                {isFrontImageTaken ? " Front of Card" : "Back of Card"}
              </h4>
            </div>
            {isFrontImageTaken ? (
              <Camera
                setFrontImage={setFrontImage}
                setFrontImageURL={setFrontImageURL}
              />
            ) : (
              <Camera
                setBackImage={setBackImage}
                setBackImageURL={setBackImageURL}
              />
            )}
          </div>
        </>
      ) : (
        <ShowCapturedImages
          frontImageURL={frontImageURL}
          backImageURL={backImageURL}
          retakeImage={retakeImageHandler}
        />
      );
    return (
      <>
        <FileUpload
          heading="Front of your Card"
          onFileSelect={(file) => setFrontImage(file)}
          disabled={disabled}
        />
        <h6 className="imageName">{frontImage?.name }{frontImage?.name && <img src={succes} alt="" height="30"/>}</h6>
        <FileUpload
          heading="Back of your Card"
          onFileSelect={(file) => setBackImage(file)}
          disabled={disabled}
        />
        <h6 className="imageName">{backImage?.name}{backImage?.name&& <img src={succes} alt="" height="30"/>}</h6>
      </>
    );
  };

  export default MobileComponent