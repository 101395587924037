import Button from "../../../Components/Button/Button";
import { useHistory } from "react-router-dom";
import BusinessSVG from "./BusinessSVG";
function Introduction({ handleToUpdate }: { handleToUpdate: () => void }) {
  const history = useHistory();
  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="logoContainer">
            <BusinessSVG />
          </div>
          <div className="titleContainerSuccess">
            <h4 className="title">
              Please provide a document that shows you are legally authorized to
              make financial decisions for the business entity.
            </h4>
          </div>
          <div
            className="announcementTitle"
            onClick={() => {
              history.push("/", { step: 1 });
              sessionStorage.removeItem("userId");
            }}
          >
            * I do not have a copy of my documents available. I will complete my
            application later.
          </div>
          <Button
            label="Continue"
            className="buttonContinue"
            onClick={handleToUpdate}
          />
        </div>
      </div>
    </div>
  );
}

export default Introduction;
