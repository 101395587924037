import React from "react";
import { AppContext } from "../Context/Context";

type UserDetailsSectionProps = {
  children: React.ReactNode;
};
const UserDetailsSection: React.FC<UserDetailsSectionProps> = (
  props
): React.ReactElement => {
  const [isUSResident, setusResident] = React.useState(true);
  const [phone_Number, set_phone_number] = React.useState("+1 ");
  const [userId, setUserId] = React.useState<any>("");

  const [token, setToken] = React.useState("");
  const [investorOptionSelect, setinvestorOptionSelect] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmailAddress] = React.useState("");
  const [einNumber, setEinNumber] = React.useState("");
  const [loginEmail, setLoginEmail] = React.useState("");
  const [isExistingUser, setIsExistingUser] = React.useState(false);
  const [company, setCompany] = React.useState("");
  const [citizenship, setCitizenship] = React.useState("");
  const [investorType, setinvestorType] = React.useState("");
  const [applicationStatus, setApplicationStatus] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [companyErr, setCompanyErr] = React.useState("");

  const userDetails: any = {
    isUSResident,
    phone_Number,
    userId,
    token,
    investorOptionSelect,
    firstName,
    lastName,
    investorType,
    email,
    company,
    einNumber,
    loginEmail,
    citizenship,
    applicationStatus,
    isExistingUser,
    firstNameErr,
    lastNameErr,
    companyErr,
    setusResident,
    set_phone_number,
    setUserId,
    setToken,
    setinvestorOptionSelect,
    setFirstName,
    setLastName,
    setEmailAddress,
    setCompany,
    setCitizenship,
    setinvestorType,
    setApplicationStatus,
    setEinNumber,
    setLoginEmail,
    setIsExistingUser,
    setFirstNameErr,
    setLastNameErr,
    setCompanyErr,
  };

  return (
    <AppContext.Provider value={userDetails}>
      {props.children}
    </AppContext.Provider>
  );
};

export default UserDetailsSection;
