import React from "react";
import MainStepper from "../../../Components/Stepper/MainStepper";
import Introduction from "../../Onboarding/EntityBusiness/Introduction";
import { Routes } from "../../../Constant/Routes/Routes";
import MessageScreen from "../../../Components/SuccessScreen/SuccessScreen";
import Upload from "../../Onboarding/EntityBusiness/upload";
const arraySteps = [
  { title: "Company Representative" },
  { title: "Upload Documents" },
  { title: "Success" },
];
type props = {
  title?: string;
  id?: number;
};
function EntityBusinessProof() {
  const [currentStep, setcurrentStep] = React.useState(0);

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Introduction
            handleToUpdate={() => setcurrentStep(currentStep + 1)}
          />
        );
      case 1:
        return (
          <Upload handleToUpdate={() => setcurrentStep(currentStep + 1)} />
        );
      case 2:
        return (
          <MessageScreen
            title="Success"
            heading="We have received your document"
            navigate={Routes.FINALIZE_ONBOARDING}
          />
        );
      default:
        return null;
    }
  };
  return (
    <MainStepper currentStep={currentStep} stepsArray={arraySteps}>
      {renderComponent()}
    </MainStepper>
  );
}

export default EntityBusinessProof;
