import "./SelectDropDown.css";

interface ItemProps {
  id: number | string;
  name: string;
}

interface SelectDropDownProps {
  captionKey: string;
  className: string;
  items: ItemProps[];
  handler: (value: string) => void;
  disable: boolean;
  selected: string;
}

const SelectDropDown = ({
  captionKey,
  handler,
  className,
  items,
  disable,
  selected
}: SelectDropDownProps) => {
  return (
    <select
      className={className}
      disabled={disable}
      onChange={(e) => {
        handler(e.target.value)
        sessionStorage.setItem('investorType',e.target.value)
      }}
      value={selected}
    >
      <option value="" className="optionList" disabled >
        Select One
      </option>
      {items.map((item: ItemProps) => (
        <option  className="optionList"  key={item["id"]} value={item["id"]}>
          {(item as any)[captionKey]}
        </option>
      ))}
    </select>
  );
};

export default SelectDropDown;
