import React, { ChangeEvent, FocusEvent } from "react";
import InputMask from "react-input-mask";
import Button from "../../../Components/Button/Button";
import InputField from "../../../Components/Input/InputField/InputField";
import "./style.css";
import { Address } from "../../../Api/onBoarding";
import { AppContext } from "../../../Context/Context";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import { Info } from "../../../Api/onBoarding";
import { useHistory } from "react-router-dom";

function AddressInformation({
  handleToUpdate,
  setcurrentStep,
}: {
  handleToUpdate: () => void;
  setcurrentStep: (number: number) => void;
}) {
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [cityError, setCityError] = React.useState<string>("");
  const [address1Error, setAddress1Error] = React.useState<string>("");
  const [address2Error, setAddress2Error] = React.useState<string>("");
  const [state, setStateName] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [states, setStates] = React.useState<any>(["chicago", "poland"]);
  const [country, setCountry] = React.useState("");
  const [countryError, setCountryError] = React.useState<string>("");
  const { userId, isUSResident, investorType } = React.useContext(AppContext);
  let localVar: any = sessionStorage.getItem("usResident");
  const history = useHistory();

  const isInputEmpty =
    address1 &&
    city &&
    (state || country) &&
    zipCode &&
    cityError === "" &&
    countryError === "" &&
    address1Error === "" &&
    address2Error === "";

  // Zip code validation logic
  const isValidZipCode = JSON.parse(localVar)
    ? zipCode.length !== 5
    : zipCode.length < 5 || zipCode.length > 10;

  React.useEffect(() => {
    Address.get()
      .then((res) => {
        setStates(Object.values(res.question1[3].options));
      })
      .catch((err) => console.log("Failed!"));
  }, []);

  React.useEffect(() => {
    Info.GetUserDetails()
      .then((res) => {
        if (
          res.message.data.addressinfodata.address1 !== undefined &&
          res.message.data.addressinfodata.address1 !== "" &&
          res.message.data.addressinfodata.docFile === null
        ) {
          setcurrentStep(2);
        } else if (
          res.message.data.addressinfodata.address1 !== undefined &&
          res.message.data.addressinfodata.address1 !== "" &&
          res.message.data.addressinfodata.docFile !== null
        ) {
          setcurrentStep(3);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleAddress1Change = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    if (value.length > 30) {
      setAddress1Error("Must be 30 characters or less.");
    } else {
      setAddress1(value);
      setAddress1Error(""); // No error
    }
  };

  // Handle when first name field loses focus (onBlur)
  const handleAddress1Blur = (event: FocusEvent<HTMLInputElement>): void => {
    // Clear error when field loses focus if the input is valid
    if (address1.length <= 30) {
      setAddress1Error("");
    }
  };

  const handleAddress2Change = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    if (value.length > 30) {
      setAddress2Error("Must be 30 characters or less.");
    } else {
      setAddress2(value);
      setAddress2Error(""); // No error
    }
  };

  // Handle when first name field loses focus (onBlur)
  const handleAddress2Blur = (event: FocusEvent<HTMLInputElement>): void => {
    // Clear error when field loses focus if the input is valid
    if (address2.length <= 30) {
      setAddress2Error("");
    }
  };

  const handleCityChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const city: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/;

    if (city.length > 30) {
      setCityError("Must be 30 characters or less.");
    } else if (!characterRegex.test(city)) {
      setCityError("City must contain only alphabetic characters and spaces.");
    } else {
      setCity(city);
      setCityError(""); // No error
    }
  };

  const handleCityBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/;
    if (characterRegex.test(city) && city.length <= 30) {
      setCityError("");
    }
  };

  const handleCountryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const country: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/;
    if (country.length > 30) {
      setCountryError("Must be 30 characters or less.");
    } else if (!characterRegex.test(country)) {
      setCountryError(
        "Country must contain only alphabetic characters and spaces."
      );
    } else {
      setCountry(country);
      setCountryError(""); // No error
    }
  };

  const handleCountryBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/;
    if (characterRegex.test(country) && country.length <= 30) {
      setCountryError("");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const userAddressDetails = {
      address1,
      address2,
      city,
      state,
      country,
      zipCode,
      userId,
    };

    try {
      const response = await Address.submit(userAddressDetails);
      if (
        response.message.logout ||
        response.message.detail === "Token not found."
      ) {
        history.push("/", { step: 1 });
      } else if (response.message.success) {
        handleToUpdate();
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="titleMainContainer">
            <h4 className="title">
              {investorType == "4" || investorType == "0"
                ? "Enter Your Address"
                : "Enter your Company address"}
            </h4>
          </div>

          <div style={{ marginTop: 20 }}>
            <InputField
              type="text"
              placeholder="Address 1"
              name="address1"
              id="address1"
              value={address1}
              onChange={handleAddress1Change}
              onBlur={handleAddress1Blur}
              className="inputField"
            />
            {address1Error !== "" && (
              <p style={{ color: "red" }}>{address1Error}</p>
            )}
            <InputField
              type="text"
              placeholder="Address 2 (Optional)"
              name="address2"
              id="address2"
              value={address2}
              onChange={handleAddress2Change}
              onBlur={handleAddress2Blur}
              className="inputField"
            />
            {address2Error !== "" && (
              <p style={{ color: "red" }}>{address2Error}</p>
            )}
            <InputField
              type="text"
              placeholder="City"
              name="city"
              id="city"
              value={city}
              onChange={handleCityChange}
              onBlur={handleCityBlur}
              className="inputField"
            />
            {cityError !== "" && <p style={{ color: "red" }}>{cityError}</p>}
            {JSON.parse(localVar) ? (
              <select
                value={state}
                className="inputField"
                onChange={(e) => setStateName(e.target.value)}
              >
                <option value="" disabled className="optionList">
                  Select state
                </option>
                {states.map((option: any, index: any) => (
                  <option key={index} value={option} className="optionList">
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <InputField
                type="text"
                placeholder="Country"
                name="country"
                id="country"
                value={country}
                onChange={handleCountryChange}
                onBlur={handleCountryBlur}
                className="inputField"
              />
            )}
            {countryError !== "" && (
              <p style={{ color: "red" }}>{countryError}</p>
            )}
            <InputMask
              mask={JSON.parse(localVar) ? "99999" : "9999999999"}
              className="inputField"
              maskChar={null}
              onChange={(e) => setZipCode(e.target.value)}
              value={zipCode}
              placeholder={
                JSON.parse(localVar)
                  ? "Zip Code (Must be 5 digits)"
                  : "Zip Code (Must be 10 digits or less)"
              }
            />
          </div>
          <PrivacyContainer />
          <div className="continueBtn">
            <Button
              label="Continue"
              className={
                !isInputEmpty || isValidZipCode
                  ? "deactiveButton"
                  : "buttonContinue"
              }
              onClick={handleSubmit}
              disabled={!isInputEmpty || isValidZipCode}
              icon={
                loading ? (
                  <i className="fas fa-circle-notch fa-spin mt-2"></i>
                ) : null
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressInformation;
