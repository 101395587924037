import { InvestAmount } from "../../Constant/onboardingData";
import RadioButton from "../RadioButton/RadioButton";
import "./style.css";

const InvestorWorth = (isEntity: any) => (
  <div className="radioButtonContainer">
    <div className="radioButtonTitle">
      {" "}
      <h4 className="buttonTitle">
        Select one of the options below that best describes how you qualify as
        an accredited investor:
      </h4>
    </div>
    <div>
      {InvestAmount.map(({ discripton, id }) => {
        return <RadioButton description={discripton} id={id} key={id} />;
      })}
    </div>
    <div className="mt-2">
      For now, CARL investments are restricted to accredited investors.
    </div>
  </div>
);

export default InvestorWorth;
