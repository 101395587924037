import React, { useEffect } from "react";
import InputMask from "react-input-mask";
import { AppContext } from "../../../Context/Context";
import Button from "../../../Components/Button/Button";
import { authentication } from "../../../Api/authentication";
import Logo from "../../../Assets/Images/logo.png";
import "./../../../Components/Input/PhoneNumberInput/style.css";
import "../NumberVarification/style.css";
import { CARL_LINK } from "../../../Constant/onboardingData";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import InputField from "../../../Components/Input/InputField/InputField";
import { validateEmail } from "../../../utils";

const NumberVarification = ({
  handleToUpdate,
  setIsEmail,
  setExistingEmail,
}: {
  handleToUpdate: () => void;
  setIsEmail: (email: boolean) => void;
  setExistingEmail: (email: string) => void;
}) => {
  const {
    phone_Number,
    set_phone_number,
    setUserId,
    isUSResident,
    loginEmail,
    setLoginEmail,
  } = React.useContext(AppContext);

  const [error, setError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [isInvalidEmail, setisInvalidEmail] = React.useState(false);
  const [errorSWW, setErrorSWW] = React.useState(false);
  const [errorLength, setErrorLength] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isExistingUser, setIsExistingUser] = React.useState(true);
  const phone_number = phone_Number.replace(/[^+0-9]/g, "");
  const checkNumber = parsePhoneNumberFromString(phone_number);
  const { setusResident } = React.useContext(AppContext);

  const loginHandler = async () => {
    try {
      const response = await authentication.signIn(phone_number, loginEmail);
      if (response.status) {
        setIsEmail(response.message.to_email);
        setExistingEmail(response.message.email);
        set_phone_number(response.message.phone_number);
        setUserId(response.message.id);
        sessionStorage.setItem("userId", JSON.stringify(response.message.id));
        handleToUpdate();
        setLoading(false);
      }
      setErrorSWW(true);
      setLoading(false);
    } catch {
      setLoading(true);
    }
  };
  const Submit = async () => {
    setEmailError(false);
    setisInvalidEmail(false);
    setError(false);
    setErrorSWW(false);
    setErrorLength(false);
    setLoading(true);

    if (loginEmail && loginEmail.length <= 40 && validateEmail(loginEmail)) {
      loginHandler();
    } else if (loginEmail.length > 40) {
      setErrorLength(true);
      setLoading(false);
      return;
    } else {
      setisInvalidEmail(true);
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    if (errorSWW) {
      setTimeout(() => {
        setErrorSWW(false);
      }, 5000); // 5000 ms = 5 seconds
    }
  }, [errorSWW]);

  useEffect(() => {
    setLoginEmail("");
  }, []);

  return (
    <div className="container">
      <div className="header">
        <div>
          <>
            <div className="verificationHeader">
              <img src={Logo} alt="logo" />
              <h5 className="mainTitle">Welcome To CARL</h5>
            </div>
            <div>
              <h6 className="title">Enter your Email to continue</h6>
            </div>
            <div className="mt-2">
              <h6 className="titleNumber">Email Address</h6>
            </div>
            <InputField
              type="email"
              placeholder=""
              name="email"
              value={loginEmail}
              onChange={(e) => {
                setEmailError(false);
                setisInvalidEmail(false);
                setLoginEmail(e.target.value);
                sessionStorage.setItem("email", JSON.stringify(e.target.value));
              }}
              onBlur={() => {}}
              className="Input"
            />
          </>
        </div>
        {error ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Please ensure that you enter a correct phone number along with a
            valid country code.
          </h6>
        ) : emailError ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            {/* You are a new user please enter email address too. */}
          </h6>
        ) : isInvalidEmail ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Please enter a valid email address.
          </h6>
        ) : errorLength ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Email must be 40 characters or less.
          </h6>
        ) : errorSWW ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Email already exists.
          </h6>
        ) : null}
        <div className="continueBtn">
          <Button
            label="Continue"
            className="buttonContinue"
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            disabled={loading}
            onClick={Submit}
          />
        </div>
        <div>
          <p className="footer">
            Trouble logging in ? Email us at
            <span className="footerSpan">
              <a
                className="termLink"
                href={`mailto:${CARL_LINK}`}
                target="_blank"
                rel="noreferrer"
              >
                {CARL_LINK}
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NumberVarification;
