import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import PersonalInfoScreen from "../Screens/Onboarding/PersonalInfo/PersonalInfoScreen";
import IdentificationStepper from "../Screens/AllSteppers/IdentificationStepper/IdentificationStepper";
import AddressStepper from "../Screens/AllSteppers/AdressStepper/AdressStepper";
import FinalizeOnboarding from "../Screens/AllSteppers/FinalizeOnboarding/FinalizeOnboarding";
import UserDetailsSection from "../Provider/contextProvider";
import PrivateRoutes from "../Authentication/PrivateRoutes";
import HomeScreen from "../Screens/HomeScreen/HomeScreen";
import LoginStepper from "../Screens/AllSteppers/LoginStepper/loginStepper";
import AccreditedStepper from "../Screens/AllSteppers/AccreditedInvestor/AccreditedInvestor";
import EntityBusinessProof from "../Screens/AllSteppers/Entity_Business_proof/EntityBusinessProof";
import TagManager from "react-gtm-module";
import "../App.css";
import Dashboard from "../Components/Dashboard/Dashboard";
import RejectScreen from "../Screens/HomeScreen/RejectScreen";

const AppContent = () => {
  const location = useLocation(); // Hook used inside Router context

  return (
    <Switch key={location.pathname}>
      <PrivateRoutes
        exact
        path="/PersonalInfo"
        component={PersonalInfoScreen}
      />
      <PrivateRoutes
        exact
        path="/Identification"
        component={IdentificationStepper}
      />
      <PrivateRoutes
        exact
        path="/AddressVerification"
        component={AddressStepper}
      />
      <PrivateRoutes
        exact
        path="/FinalizeOnboarding"
        component={FinalizeOnboarding}
      />
      <PrivateRoutes
        exact
        path="/AccreditedDocs"
        component={AccreditedStepper}
      />
      <PrivateRoutes
        exact
        path="/BusinessVerification"
        component={EntityBusinessProof}
      />
      <PrivateRoutes exact path="/HomeScreen" component={HomeScreen} />
      <PrivateRoutes exact path="/RejectScreen" component={RejectScreen} />
      <PrivateRoutes exact path="/dashboard" component={Dashboard} />
      <Route path="/" component={LoginStepper} />
    </Switch>
  );
};

const Navigation = () => {
  const userId = sessionStorage.getItem("userId");
  const tagManagerArgs = {
    gtmId: "GTM-P2J57HX",
    events: {
      sendUserInfo: userId,
      Platform: "Web Onboarding",
    },
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <UserDetailsSection>
      <Router basename={process.env.PUBLIC_URL}>
        <AppContent />
      </Router>
    </UserDetailsSection>
  );
};

export default Navigation;
