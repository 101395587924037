import React from "react";
import MainStepper from "../../../Components/Stepper/MainStepper";
import Info from "../../Login/Info/Info";
import NumberVarification from "../../Login/NumberVarification";
import OTP from "../../Login/OTP";
import EmailVerification from "../../Login/EmailVerification";
import { useLocation } from "react-router-dom";

type Props = {
  title?: string;
  id?: number;
  val?: string;
};

type LocationState = {
  step?: number;
};

const arraySteps = [
  { title: "Info" },
  { title: "Number Verification" },
  { title: "Email Verification" },
  { title: "OTP" },
];

const LoginStepper = () => {
  const location = useLocation<LocationState>();
  const { step } = location.state || {};

  const [currentStep, setCurrentStep] = React.useState(step || 0);
  const [isEmail, setIsEmail] = React.useState(true);
  const [existingEmail, setExistingEmail] = React.useState("");

  const handleToUpdate = (Title?: Props) => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <Info handleToUpdate={handleToUpdate} />;
      case 1:
        return (
          <NumberVarification
            handleToUpdate={(val) =>
              val
                ? setCurrentStep(currentStep + 2)
                : setCurrentStep(currentStep + 1)
            }
            setIsEmail={setIsEmail}
            setExistingEmail={setExistingEmail}
          />
        );
      case 2:
        return (
          <EmailVerification
            handleToUpdate={handleToUpdate}
            setIsEmail={setIsEmail}
            setExistingEmail={setExistingEmail}
          />
        );
      case 3:
        return (
          <OTP
            handleToUpdate={() => setCurrentStep(currentStep - 1)}
            stepLength={currentStep}
            isEmail={isEmail}
            existingEmail={existingEmail}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <MainStepper
        currentStep={currentStep}
        stepsArray={arraySteps}
        styles="display-none"
      >
        {renderComponent()}
      </MainStepper>
    </div>
  );
};

export default LoginStepper;
