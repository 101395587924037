import Button from "../../../Components/Button/Button";
import "./style.css";
import { useHistory } from "react-router-dom";
import CardSVG from "./CardSVG";

const Identification = ({ handleToUpdate }: { handleToUpdate: () => void }) => {
  const history = useHistory();

  return (
    <div className="container">
      <div className="header">
        <div className="logoContainer">
          <CardSVG />
        </div>

        <div>
          <h4 className="title">Next, we need to verify your identity</h4>
        </div>
        <div
          className="announcementTitle"
          onClick={() => {
            history.push("/", { step: 1 });
            sessionStorage.removeItem("userId");
          }}
        >
          * I do not have a copy of my documents available. I will complete my
          application later.
        </div>
        <div className="continueBtn">
          <Button
            label="Continue"
            className="buttonContinue"
            onClick={handleToUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default Identification;
